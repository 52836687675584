<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'definitions-index' }" />

    <template v-if="definition">
      <h2 class="mb-4">Термин (id: {{ id }})</h2>

      <v-sheet class="pa-4" elevation="3">
        <definition-form
          :definition="definition"
          :loading="updating"
          @change-field="changeField"
          @submit="updateDefinition"
        />
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Термин с id "{{ id }}" не найден</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { editorForm } from "~/mixins/multi-editor/editor-form";

import DefinitionForm from "~/components/definitions/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      definition: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchDefinition();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchDefinition() {
      this.loading = true;

      try {
        this.definition = await this.$axios.$get(`definitions/${this.id}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Термин с id: "${this.id}" не найден ${html}`);
      } finally {
        this.loading = false;
      }
    },

    async updateDefinition() {
      if (this.updating) return;

      this.updating = true;

      try {
        await this.$axios.$patch(`definitions/${this.id}`, this.definition);
        await this.fetchDefinition();

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.definition)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.definition, payload);
    }
  },
  components: {
    DefinitionForm
  }
};
</script>
